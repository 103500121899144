import "./Dropdown.css";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Menu from "@material-ui/core/Menu";
import { MenuItem } from "@material-ui/core";

const Dropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
      <div
        className="textcolor"
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <AnchorLink>
          <b> The Collection</b>
        </AnchorLink>
      </div>

      <Menu
        className="colr"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        style={{ marginTop: "45px" }}
      >
        <MenuItem
          selected
          className="MenuItem"
          onClick={() => {
            window.open("https://opensea.io/collection/nifty-ape-nation", "_blank");
          }}
        >
          <AnchorLink className="dropdown" href="/twitter">
            OpenSea
          </AnchorLink>
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("https://looksrare.org/collections/0xaef8B6346CA4DaDaA71783dDdF4a3D00633B679d", "_blank");
          }}
        >
          <AnchorLink className="dropdown" href="/discord">
            LOOKSRARE
          </AnchorLink>
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("https://rarible.com/nifty-ape-nation", "_blank");
          }}
        >
          <AnchorLink className="dropdown" href="/youtube">
            Rarible
          </AnchorLink>
        </MenuItem>
      </Menu>
    </>
  );
};
export default Dropdown;
