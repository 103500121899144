import React from "react";
import { Grid } from "@mui/material";
import "./Modal.css";
import Modal from "@mui/material/Modal";
import ReactPlayer from "react-player";

export default function Portfollio(props) {
  const { open, videoURL, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className="classes-modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Grid item xs={10} sm={9} md={7} lg={5} sx={{ justifyContent: "center" }}>
        <div className="chk">
          <ReactPlayer controls={true} playing={true} url={videoURL} />
        </div>
      </Grid>
    </Modal>
  );
}
