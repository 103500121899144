import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import DNABlock from "../../img/logo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";

import i1 from "../../img/i1.png";
import i2 from "../../img/i2.png";
import i3 from "../../img/i3.png";
import i4 from "../../img/i4.png";
import i5 from "../../img/i5.png";
import i6 from "../../img/i6.png";
import i7 from "../../img/i7.png";
import TermAndConditionModal from "./Modal/TermAndConditionModel";



import "./Footer.css";

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//   const top = 50 + rand();
//   const left = 50 + rand();

//   return {
//     top: "56%",
//     left: "49%",
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }


export default function Footer({ icons, carouselData }) {
  const history = useHistory();


  // const [open, setOpen] = React.useState(false);
  const [Topen, setTOpen] = React.useState(false);
  // const [videoURL, setVideoURL] = React.useState();

  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  function handleRoadmap() {
    history.push(`/#/Roadmap`);
  }

  return (
    <Grid
      container
      alignItems="center"
      justify="space-around"
      className="foot_main"
    >
      <Grid item container xs={12} md={3} lg={3} justify="center">
        {/* <Grid item xs={12} className="dnaa_img_wrap">
          <img src={DNABlock} alt="dna-block" className="dnaa_imgg" onClick={handleClick} />
        </Grid> */}
        {/* <Grid item xs={12}>
          <Typography style={{cursor:"pointer"}} className="terms2" onClick={() => setTOpen(true)}>
            <div>
            <p style={{ fontSize: ".65em "  }}>
              Nifty Ape Nation<br></br> Terms and Conditions
              </p>
            </div>
          </Typography>
        </Grid> */}
      </Grid>
      <Grid
        item
        container
        justify="center"
        xs={12}
        md={5}
        lg={5}
        className="footer_icons"
      >
        <Grid item container justify="center">
          {carouselData?.discordLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.discordLink)}
                src={i1}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.twitterLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.twitterLink)}
                src={i5}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.instagramLink ? (
            <Grid item className="icons">
              <img
                onClick={() =>
                  window.open(carouselData.instagramLink)
                }
                src={i2}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.redditLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.redditLink)}
                src={i3}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.tiktokLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.tiktokLink)}
                src={i4}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.twitchLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.twitchLink)}
                src={i6}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.youtubeLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.youtubeLink)}
                src={i7}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item>
          <Typography className="terms">
          <p style={{ fontSize: ".65em "  }}>
            {" "}
            © 2022 Nifty Ape Nation Powered by <a style={{ color: "#fff" }} href="https://www.dnablock.com/" >DNABLOCK</a>. All rights reserved.
            <br></br>
            <span style={{cursor:"pointer"}} onClick={() => setTOpen(true)}>Terms and Conditions ·</span> Privacy Policy </p>
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center">
      </Grid>
          <TermAndConditionModal open={Topen} setOpen={setTOpen} />   
    </Grid>
  );
}

