
import './App.css';

import Mint from './components/Mint';

import { BrowserRouter as Router, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Mint />
      </Switch>
    </Router>
  );
}

export default App;
