import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import BasicModal from "../Modal";
import { Grid } from "@material-ui/core";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Dropdown from "./Dropdown/Dropdown";
import Popup from "./Popup/Popup";
import NavBarLogo from "../../img/logo.png";
import { Button } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import i1 from "../../img/i1.png";
import i5 from "../../img/i5.png";
import i2 from "../../img/i2.png";
import i7 from "../../img/i7.png";
import "./PrimaryNavBar.css";

const useStyles = makeStyles((theme) => ({
  grow: {
    // flexGrow: 1,
  },
  title: {
    display: "none",
    color: "black",
    [theme.breakpoints.up("xs")]: {
      display: "block",
      color: "black",
      cursor: "pointer",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#EEEEE9",
    "&:hover": {
      backgroundColor: "#EEEEE9",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    color: "black",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      // flexDirection: "column",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  navbar1: {
    padding: "10px",
    cursor: "pointer",
  },
  paper: {
    position: "relative",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 0 4px",
    borderRadius: "10px",
    padding: theme.spacing(2, 4, 3),
  },
  drop: {
    backgroundColor: "#3b3b3b",
  },
}));

export default function PrimaryNavBar() {
  const history = useHistory();

  const scrollToId = window.location.href.split("/")[4];

  function handleNavigate() {
    history.push(`/`);
  }
  if (scrollToId) {
    setTimeout(() => {
      const pageScroll = document.getElementById(`/${scrollToId}`);
      pageScroll.scrollIntoView({ behavior: "smooth" });
    }, 0);
    setTimeout(() => {
      handleNavigate();
    }, 1000);
  }

  function handleRoadmap() {
    history.push(`/#/Roadmap`);
  }

  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open, setOpen] = React.useState(false);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem>
      <a class="image-2" onClick={() => {
        window.open("https://nifty-ape-nation.webflow.io/#roadmap", "_blank");}}>
        <AnchorLink className="anchor">
          <b> Roadmap</b>
        </AnchorLink>
       </a> 
      </MenuItem>
      <MenuItem>
        <Popup />
      </MenuItem>
      <MenuItem>
        <Dropdown/>
      </MenuItem>
      {/* <MenuItem>
        <div className="anchor">
          <Button
            variant="contained"
            size="small"
            className="mint-button"
            onClick={() => setOpen(true)}
          >
            Need Help?
          </Button>
        </div>
      </MenuItem> */}
      <div className="icon_w">
      {/* <div class="header_bar"> */}
      {/* <a class="image-2" onClick={() => {
        window.open("https://www.instagram.com/NiftyApeNation/", "_blank");}}>      
      <InstagramIcon font-size="small" color="primary" />
      </a>
      <a class="image-2" onClick={() => {
        window.open("https://www.youtube.com/c/niftyapenation/videos", "_blank");}}>
      <YouTubeIcon font-size="small" color="primary" />
      </a>
      <a class="image-2" onClick={() => {
          window.open("https://twitter.com/ThaRealNAN", "_blank");}}>
      <TwitterIcon font-size="small" color="primary" />
      </a> */}
      {/* </div> */}
      </div>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        color="white"
        style={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <Toolbar style={{ justifyContent: "space-between", height: "auto", flexDirection: "column" }}>
          <img
            className="logo"
            style={{ cursor: "pointer" }}
            src={NavBarLogo}
            alt="logo"
            onClick={handleNavigate}
          />

          <Grid className={classes.sectionDesktop}>
            <div className="navbar1">
              <h4 className="tt">
                {" "}
                <a class="image-2" onClick={() => {
        window.open("https://nifty-ape-nation.webflow.io/#roadmap", "_blank");}}>
                  <b> Roadmap </b>
                </a>
              </h4>
            </div>
            <div className="navbar1">
              <h4 className="tt">
                <Popup />
              </h4>
            </div>
            <div className="navbar1">
              <h4 className="tt">
                <Dropdown />
              </h4>
            </div>
            {/* <div class="header_bar">
              <div class="icon_w">
                <a class="image-2" onClick={() => {
                    window.open("https://twitter.com/ThaRealNAN", "_blank");}}>
                  <img src={i5} loading="lazy" alt="" class="image-2"/></a>
                  </div>
              <div class="icon_w">
                <a class="image-2" onClick={() => {
                    window.open("https://www.instagram.com/NiftyApeNation/", "_blank");}}>
                <img src={i2} loading="lazy" alt="" class="image-2"/></a>
                </div>
              <div class="icon_w">
              <a class="image-2" onClick={() => {
                    window.open("https://www.youtube.com/c/niftyapenation/videos", "_blank");}}>
                <img src={i7} loading="lazy" alt="" class="image-2"/></a>
                </div>
            </div> */}
            <div className="last">
              {/* <Button
                variant="contained"
                size="small"
                className="mint-button"
                onClick={() => setOpen(true)}
              >
                Need Help?
              </Button> */}
            </div>
          </Grid>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon style={{ color: "white", fontSize: "40" }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}

      <Grid sx={{ justifyContent: "center" }} item xs={10} sm={9} md={7} lg={5}>
        <BasicModal
          open={open}
          videoURL={
            "https://media.discordapp.net/attachments/912752884431597609/913540505864921138/Instructional_Video_final.mp4"
          }
          setOpen={setOpen}
        />
      </Grid>
    </div>
  );
}
