import React from "react";
import {  Grid } from "@material-ui/core";
import "./Modal.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";


import { useSpring, animated } from "react-spring";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        // border: '2px solid #000',
        background: "rgba(255, 255, 255, 0.9)",
        backgroundColor: "black",
        boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.34)",
        borderRadius: "20px",
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
        width: "auto",
        height: "auto",
        [theme.breakpoints.down("xs")]: {
            width: "300px",
        },
    },
    titleP: {
        color: "white",
    },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};

export default function Portfollio(props) {
    const { open, setOpen } = props;
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const setting = {
        className: "center",
        // centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Grid container justify="space-between">
                        <Grid item xs={12} md={8} lg={8}>
                            <div>
                                <div className="term">
                                    <h3 className="term_heading">TERMS & CONDITIONS</h3>
                                    <p className="term_para">Nifty Ape Nation consists of a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to exchange digital collectibles.
                                    Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. 
                                    <br/>Furthermore, as the Nifty Ape Nation smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.
                                    This website and its connected services are provided “as is” and “as available” without a warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving Nifty Ape Nation digital collectibles.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
}
