import React, { Fragment, useEffect, useState } from "react";
import Web3 from "web3";
import Button from "@mui/material/Button";
import { Container, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";
import BasicModal from "./Modal";
import MuiAlert from "@mui/material/Alert";
import { shortenWalletAddress } from "../util/helper";
import { niftyApeNationAbi } from "../util/abi";
import config from "../util/config.json";
import NavBar from "../components/NavBar/NavBar";
import Roadmap from "./Roadmap/Roadmap";
import "./Mint.css";
import { isBrowser, isMobile } from "react-device-detect";
import { MobileMintInfo, MobileMetamaskInfo } from "./MobileInfo";
import Footer from "../components/Footer/Footer";
import i1 from "../img/i1.png";
import i2 from "../img/i2.png";
import i3 from "../img/i3.png";
import i4 from "../img/i4.png";
import i5 from "../img/i5.png";
import i6 from "../img/i6.png";
import i7 from "../img/i7.png";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Mint = () => {
  const selectedChainId = config.CHAIN_ID;
  const selectedChainName = config.CHAIN_NAME;
  const [open, setOpen] = useState(false);
  const [contentData, setContentData] = useState();
  const icons = [i1, i2, i3, i4, i5, i6, i7];

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`https://ape-nation-be.herokuapp.com/get-content`)
      .then((res) => {
        setContentData(res.data);
      })
      .catch((err) => console.log("error", err));
  }, []);

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      setMetamaskInstalled(true);
      //initialize web3
      window.web3 = new Web3(window.ethereum);
      //enable smart contract revert message handling
      window.web3.eth.handleRevert = true;

      //initalize contract
      window.nanContract = new window.web3.eth.Contract(
        niftyApeNationAbi,
        config.NAN_CONTRACT_ADDRESS
      );
      //retrieve price, pause state etc
      getOnChainValues();

      // Add listeners start
      window.ethereum.on("accountsChanged", (accounts) => {
        if (!accounts.length) {
          //if all accounts disconnects
          setConnected(false);
        }
        setAccount(accounts[0]);
      });

      window.ethereum.on("chainChanged", (chainId) => {
        if (window.web3.utils.hexToNumber(chainId) !== selectedChainId) {
          setInvalidChainId(true);
        } else {
          setInvalidChainId(false);
          getOnChainValues();
        }
      });
    } else {
      setMetamaskInstalled(false);
    }
  }, [selectedChainId]);

  const [metamaskInstalled, setMetamaskInstalled] = useState(false);
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState("");
  const [mintAmount, setMintAmount] = React.useState(1);
  const [invalidChainId, setInvalidChainId] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertSeverity, setAlertSeverity] = React.useState("warning");
  const [loading, setLoading] = React.useState(false);

  //on-chain data
  const [tokenPrice, setTokenPrice] = React.useState(0);
  const [maxMintAmount, setMaxMintAmount] = React.useState(0);
  const [contractPaused, setContractPaused] = React.useState(false);

  const onConnect = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    if (window.web3.utils.hexToNumber(chainId) !== selectedChainId) {
      setInvalidChainId(true);
    }
    if (accounts[0]) {
      setConnected(true);
      setAccount(accounts[0]);
    } else {
      setAccount(null);
      setConnected(false);
    }
  };

  const onMint = async () => {
    checkContractStatus();
    if (contractPaused) {
      setOpenAlert(true);
      setAlertMessage("Sale is not open!");
      setAlertSeverity("warning");
      return;
    }
    try {
      setLoading(true);
      const result = await window.nanContract.methods
        .mintPublic(mintAmount)
        .send({
          from: window.ethereum.selectedAddress,
          value: mintAmount * tokenPrice,
        });

      //const tokenId = result.events.Transfer.returnValues.tokenId;
      if (result.status) {
        setOpenAlert(true);
        setAlertMessage("Your apes are minted!");
        setAlertSeverity("info");
      }
      setLoading(false);
      console.log(result);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const changeMintAmount = async (e, type) => {
    if (type === 1) {
      if (mintAmount - 1 >= 1) {
        setMintAmount(mintAmount - 1);
      }
    } else {
      if (mintAmount < maxMintAmount) {
        setMintAmount(mintAmount + 1);
      }
    }
  };
  //retrieve on-chain data from smart contract(price, paused, maxMintAmount)
  const getOnChainValues = async () => {
    //retrieve public sale token price
    const pricePerToken = await window.nanContract.methods
      .pricePerPublicToken()
      .call({ from: window.ethereum.selectedAddress });
    setTokenPrice(pricePerToken);
    //checkContractStatus(paused: true/false)
    checkContractStatus();
    //retieve maxMintAmount
    const mintAmount = await window.nanContract.methods
      .maxMintAmount()
      .call({ from: window.ethereum.selectedAddress });
    setMaxMintAmount(mintAmount);
  };

  const checkContractStatus = async () => {
    //retieve contract pause status
    const isPaused = await window.nanContract.methods
      .paused()
      .call({ from: window.ethereum.selectedAddress });
    setContractPaused(isPaused);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const connectView = (
    <Fragment>
      <p style={{ color: "#FFF" }}>
        Connect your wallet and mint some awesome Apes!
      </p>
      <Button
        name="onboardButton"
        variant="contained"
        size="large"
        sx={{ marginBottom: "2rem" }}
        onClick={(e) => onConnect(e)}
      >
        Connect
      </Button>
    </Fragment>
  );
  const mintView = (
    <Fragment>
      <Typography sx={{ fontSize: 18 }} color="#fce4ec" gutterBottom>
        <br />
        Connected: {shortenWalletAddress(account)}
        <br />1 Ape costs{" "}
        {tokenPrice ? window.web3.utils.fromWei(tokenPrice, "ether") : ""} ETH.
        (gas fees excluded.)
      </Typography>

      {invalidChainId ? (
        <Alert severity="warning">
          Please connect to {selectedChainName} network
        </Alert>
      ) : (
        ""
      )}
      {!loading ? (
        <Grid>
          <Box sx={{ "& > :not(style)": { m: 1 }, color: "white" }}>
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={(e) => changeMintAmount(e, 1)}
            >
              <RemoveIcon />
            </Fab>
            {mintAmount}

            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={(e) => changeMintAmount(e, 2)}
            >
              <AddIcon />
            </Fab>
          </Box>
          <Button
            name="mintButton"
            size="large"
            variant="contained"
            onClick={(e) => onMint(e)}
            disabled={invalidChainId}
            sx={{ marginTop: "1rem", marginBottom: "2rem" }}
          >
            Mint
          </Button>
        </Grid>
      ) : (
        <CircularProgress value={20} />
      )}
    </Fragment>
  );

  const installMetamaskView = (
    <Fragment>
      <br />
      <Alert severity="warning">
        You need to install metamask to mint Apes.
      </Alert>
      <p></p>
    </Fragment>
  );

  return (
    <Fragment>
      <div className="App">
        <header className="App-header">
          <NavBar />
        </header>

    <Grid sx={{ padding: "20.25% 0 0 0", position: "relative" }} >
    {/* <Grid sx={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/741304644?autoplay=1&amp;muted=1&amp;loop=1&amp;h=bc17e5c753&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullScreen
            className="new"
            title="Nanvideo"
          ></iframe> */}
        </Grid>
        <Grid>

        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          {metamaskInstalled
            ? connected
              ? mintView
              : connectView
            : installMetamaskView}

          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert
              onClose={handleAlertClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </div>
      <Container>
        {/* <Grid id="/Roadmap" className="roadmap-style">
          <Roadmap roadmapData={contentData?.roadmapData} />
        </Grid> */}
      </Container>
      <Footer icons={icons} carouselData={contentData?.carouselData[0]} />
      <Grid sx={{ justifyContent: "center" }} item xs={10} sm={9} md={7} lg={5}>
        <BasicModal
          open={open}
          videoURL={
            "https://media.discordapp.net/attachments/912752884431597609/913540505864921138/Instructional_Video_final.mp4"
          }
          setOpen={setOpen}
        />
      </Grid>
    </Fragment>
  );
};

export default Mint;
