import * as React from "react";
import { Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./Popup.css";

export default function Popup() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="titlec" onClick={handleClickOpen}>
        <AnchorLink>
          <b>Fight Club </b>
        </AnchorLink>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <i>
              “As soon as the first 888 are minted, Fight Club unlocks. Mint to
              guarantee a spot in Fight Club Today”
            </i>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.open("https://mint.niftyapenation.com/", "_blank");
            }}
            autoFocus
            className="colors"
          >
            Mint Now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
